/* eslint-disable @typescript-eslint/no-shadow */
import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import NotFound from '../views/NotFound.vue';
import Download from '../views/Download.vue';
import Finished from '../views/Finished.vue';

Vue.use(VueRouter);

export enum RouteNames {
  Finished = 'Finished',
  Download = 'Download',
  NotFound = '404',
}

const routes: Array<RouteConfig> = [
  /**
   * i.E. ueberland/finished
   * The confirmation Page that is displayed after the main form is submitted
   */
  {
    path: '/download/:token',
    name: RouteNames.Download,
    props: true,
    component: Download,
  },
  {
    path: '/finished/:token',
    name: RouteNames.Finished,
    props: true,
    component: Finished,
  },
  /**
   * 404
   */
  {
    path: '/404',
    name: RouteNames.NotFound,
    meta: {
      public: true,
    },
    props: true,
    component: NotFound,
  },
  {
    path: '*',
    redirect: '404',
  },
];

const router = new VueRouter({
  routes,
  mode: 'history',
});

export default router;
