/* eslint-disable no-duplicate-imports */
/* eslint-disable import/no-unassigned-import */
import 'reflect-metadata';
import Vue from 'vue';
import Vuex from 'vuex';
import App from './App.vue';
import router from './router';
import './assets/scss/bulma.scss';
import BuefyIconRenderer from '@/components/buefy-icon-renderer.vue';
import * as Sentry from '@sentry/vue';
import { Integrations } from '@sentry/tracing';

/* eslint-disable import/no-duplicates */
import { ConfigProgrammatic } from 'buefy';
import Button from 'buefy';
import Field from 'buefy';
import Input from 'buefy';
import Loading from 'buefy';
import Message from 'buefy';
import { VueEnvHelper } from './helpers/vue-envvar-helper';

class AppBootstrap {
  constructor () {
    this.loadVueApp();
  }

  private loadVueApp () {
    Vue.component('buefy-icon-renderer', BuefyIconRenderer);
    Vue.use(Button);
    Vue.use(Field);
    Vue.use(Input);
    Vue.use(Loading);
    Vue.use(Message);
    Vue.use(Vuex);

    const envHelper = new VueEnvHelper();
    const sentryDsn = envHelper.get('SENTRY_DSN');
    if (sentryDsn) {
      Sentry.init({
        Vue,
        dsn: envHelper.get('SENTRY_DSN'),
        autoSessionTracking: true,
        environment: (envHelper.get('ENVIRONMENT') || process.env.NODE_ENV).toLowerCase(),
        integrations: [
          new Integrations.BrowserTracing(),
        ],
        release: envHelper.get('GIT_SHA'),
        tracesSampleRate: 1.0,
        logErrors: true,
      });
    }

    ConfigProgrammatic.setOptions({
      defaultIconComponent: 'buefy-icon-renderer',
    });

    Vue.config.productionTip = false;

    new Vue({
      router,
      render: h => h(App),
    }).$mount('#app');
  }
}

// eslint-disable-next-line no-new
new AppBootstrap();
