





















import { IGetFinishedDownloadDataResponse } from '@einfachgast/shared';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Component
export default class DataRequestInfoContainer extends Vue {
  @Prop()
  requestData: IGetFinishedDownloadDataResponse;
}
