import Axios from 'axios';
import { FirebaseUriHelper } from './firebase-uri-helper';

/**
 * This is a lighweight replacement of firebase.httpsCallable.call('functionName').
 * It enables you to call firebase functions in a convinient way without having to import
 * that riddicilously huge firebase-js-sdk
 */
class FirebaseCloudFunctions {
  constructor (private firebaseUriHelper: FirebaseUriHelper = new FirebaseUriHelper()) {}

  /**
   * Call a firebase cloud function
   * @param functionName the name of the cloud function you want to call
   * @param payload the Payload you want to send to the cloud function
   */
  async call (functionName: string, payload: Record<string, unknown>) {
    return await Axios.post(this.firebaseUriHelper.getFunctionsUrl(functionName), { data: payload });
  }

  /**
   * Call a firebase cloud function
   * @param functionName the name of the cloud function you want to call
   * @param payload the Payload you want to send to the cloud function
   * @param T the type of the response data
   */
  async callTyped<T> (functionName: FirebaseFunctions, payload: Record<string, unknown>): Promise<T> {
    const resp = await this.call(functionName, payload);
    const unpackedResp = resp.data?.result as T;
    return unpackedResp;
  }
}

const cloudFunctions = new FirebaseCloudFunctions();
export { cloudFunctions };

export enum FirebaseFunctions {
  downloadCoronaData = 'downloadCoronaData',
  validateCoronaToken = 'validateCoronaToken',
  getFinishedDownloadData = 'getFinishedDownloadData',
  validateDataRequest = 'validateDataRequest',
}
