































import Component from 'vue-class-component';
import Vue from 'vue';
import EinfachGastLogo from '@/components/einfach-gast-logo.vue';
import DataRequestInfoContainer from '@/components/data-request-info-container.vue';
import { Prop } from 'vue-property-decorator';
import { cloudFunctions, FirebaseFunctions } from '@/helpers/firebase/firebase-cloud-functions';
import { IGetFinishedDownloadDataResponse } from '@einfachgast/shared';
@Component({
  name: 'Finished',
  components: {
    EinfachGastLogo,
    DataRequestInfoContainer,
  },
})
export default class Finished extends Vue {
  finishedData: IGetFinishedDownloadDataResponse = null;
  loading = false;

  @Prop()
  token: string;

  async created () {
    try {
      this.loading = true;
      this.finishedData =
        await cloudFunctions.callTyped<IGetFinishedDownloadDataResponse>(FirebaseFunctions.getFinishedDownloadData, { token: this.token });
    } catch {
      this.$buefy.toast.open({
        duration: 10000,
        message: 'Fehler beim Herunterladen der Daten. Sollte der Fehler bestehen melden Sie sich bitte an den Support.',
        type: 'is-danger',
      });
    }
    this.loading = false;
  }
}
