




















import Component from 'vue-class-component';
import Vue from 'vue';
import EinfachGastLogo from '@/components/einfach-gast-logo.vue';
import { cloudFunctions, FirebaseFunctions } from '@/helpers/firebase/firebase-cloud-functions';
import { Prop } from 'vue-property-decorator';
import { ICoronaDataResponse, ICoronaDataRequest } from '@/interfaces';
import { CoronaDataRequest } from '@/models/corona-data-request';
import papa from 'papaparse';
import { RouteNames } from '@/router';
import { IDataRequestValidationResult } from '@einfachgast/shared';

@Component({
  name: 'Download',
  components: {
    EinfachGastLogo,
  },
})
export default class Download extends Vue {
  loading = false;
  downloadRequest: ICoronaDataRequest = new CoronaDataRequest();
  dataRequestValidationResult: IDataRequestValidationResult = { isValid: false, message: '' };

  @Prop()
  token: string;

  created () {
    this.downloadRequest.token = this.token;
  }

  async mounted () {
    await this.checkIfDataRequestIsValid(this.token);
  }

  async checkIfDataRequestIsValid (token: string) {
    this.loading = true;
    try {
      this.dataRequestValidationResult = await cloudFunctions.callTyped<IDataRequestValidationResult>(FirebaseFunctions.validateDataRequest, { token: token });
      if (!this.dataRequestValidationResult.isValid) {
        this.$buefy.toast.open({
          duration: 10000,
          message: 'Diese Anfrage wurde gesperrt. Bitte fordern Sie von dem Betreiber einen neuen Downloadlink an.',
          type: 'is-danger',
        });
      }
    } catch {
      this.$buefy.toast.open({
        duration: 10000,
        message: 'Fehler beim Validieren Ihrer Anfrage. Sollte der Fehler bestehen melden Sie sich bitte an den Support.',
        type: 'is-danger',
      });
    }

    this.loading = false;
  }

  async send () {
    this.loading = true;
    try {
      const downloadResponse = await cloudFunctions.callTyped<ICoronaDataResponse>(FirebaseFunctions.downloadCoronaData, this.downloadRequest);
      if (!downloadResponse.success && downloadResponse.lockedUntil > 0) {
        this.$buefy.toast.open({
          duration: 10000,
          message: `Zu viele Fehlversuche. Der Download ist bis ${new Date(downloadResponse.lockedUntil).toLocaleString()} gesperrt.`,
          type: 'is-danger',
        });
        this.loading = false;
        return;
      }
      if (!downloadResponse.success && downloadResponse.lockedUntil === -1) {
        this.$buefy.toast.open({
          duration: 10000,
          message: 'Zu viele Fehlversuche. Der Download der Daten ist gesperrt. Bitte kontaktieren Sie den Betreiber.',
          type: 'is-danger',
        });
        this.loading = false;
        return;
      }
      if (!downloadResponse.requestedData || downloadResponse.requestedData.length < 1 || Object.keys(downloadResponse.requestedData).length < 1) {
        this.$buefy.toast.open({
          duration: 10000,
          message: 'Für den angeforderten Zeitraum wurden keine Besucherdaten erfasst.',
          type: 'is-danger',
        });
        this.loading = false;
        return;
      }
      this.downloadCsv(downloadResponse);
      await this.$router.push({
        name: RouteNames.Finished,
        params: {
          token: this.downloadRequest.token,
        },
      });
    } catch {
      this.$buefy.toast.open({
        duration: 10000,
        message: 'Fehler beim Herunterladen der Daten. Sollte der Fehler bestehen melden Sie sich bitte an den Support.',
        type: 'is-danger',
      });
    }
    this.loading = false;
  }

  downloadCsv (downloadResponse: ICoronaDataResponse) {
    const csv = papa.unparse(downloadResponse.requestedData);
    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    const pseudoAnchor = window.document.createElement('a');
    pseudoAnchor.href = window.URL.createObjectURL(blob);
    pseudoAnchor.download = `${downloadResponse.filename}`;
    document.body.appendChild(pseudoAnchor);
    pseudoAnchor.click();
    document.body.removeChild(pseudoAnchor);
  }
}
